import React from 'react';
import { styled } from 'linaria/react';
import { convertPropsToObject } from '../ContentRender';
import MaxWidth from '../../Layout/MaxWidth';
import TrustPilot from '../../Util/Trustpilot';
import { RowHeader } from './RowHeader';

const StyledMax = styled(MaxWidth)`
  margin: 2rem 0 3rem;
`;

const TrustpilotStart = ({ data, index }) => {
  const props = convertPropsToObject(data);
  const show = props.show?.value;

  if (show === 'no') return null;

  return (
    <StyledMax>
      <RowHeader props={props} />
      <TrustPilot type="start-page" />
    </StyledMax>
  );
};

export default TrustpilotStart;
