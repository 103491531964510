import { useQuery } from 'react-apollo';
import blogListQuery from './blogListQuery.gql';

export const useBlog = ({
  id,
  menu,
  pathname,
  limit = false,
  parentId = false,
  exclude = [],
}) => {
  const articleLevel = menu ? 3 : 2;
  const result = useQuery(blogListQuery, {
    variables: { root: id, levels: articleLevel },
  });

  const categories = result?.data?.categories || [];

  //MAIN BLOG CATEGORY
  const mainCategory = categories.length > 0 && categories[0];

  //MENU CATEGORIES
  const menuCategories = menu
    ? categories.filter((cat) => cat.level !== articleLevel)
    : false;

  //CURRENT MENU CATEGORY
  const currentCat =
    menu &&
    menuCategories.filter((cat) => cat.primaryRoute?.path === pathname)[0];
  const currentMenuCategory = currentCat ? currentCat : mainCategory;

  //ARTICLE CATEGORIES
  let articles = categories.filter((cat) => {
    if (!exclude.includes(cat.id)) {
      return parentId
        ? cat.parentId === parentId
        : cat.primaryRoute?.path?.search(pathname) !== -1 &&
            cat.level === articleLevel;
    }
    return false;
  });

  //ARTICLES SORTING

  articles.sort((a, b) => {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    const c = new Date(
      b.mainHeader && !isNaN(b.mainHeader.substring(0, 1))
        ? b.mainHeader
        : '2000-01-01'
    );
    const d = new Date(
      a.mainHeader && !isNaN(a.mainHeader.substring(0, 1))
        ? a.mainHeader
        : '2000-01-01'
    );
    return c - d;
  });

  //ARTICLES LIMIT
  if (limit > 0) articles = articles.slice(0, limit);

  return {
    mainCategory,
    menuCategories,
    currentMenuCategory,
    articles,
  };
};
