import React, { useContext } from 'react';
import { convertPropsToObject } from '../ContentRender';
import { RowHeader } from './RowHeader';
import MaxWidth from '../../Layout/MaxWidth';
import { ProductRowComponentWrapper } from './ProductRowComponent';
import AddwishRow from '../../ui/AddwishRow/AddwishRow';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useAddwish } from '@jetshop/flight-addwish';

const AddwishComponent = ({ data }) => {
  const props = convertPropsToObject(data);
  const id = props.id.value;
  const slider = props.slider.value === 'yes' ? true : false;

  //ADDWISH
  const { selectedChannel } = useContext(ChannelContext);
  const { addWishResponseBoxItems } = useAddwish({
    boxes: {
      [id]: {
        url: selectedChannel.url,
      },
    },
    pageLoaded: true,
  });

  return (
    <MaxWidth>
      <ProductRowComponentWrapper>
        <RowHeader props={props} />
        <AddwishRow
          data={addWishResponseBoxItems[id]}
          id={id}
          slider={slider}
        />
      </ProductRowComponentWrapper>
    </MaxWidth>
  );
};

export default AddwishComponent;
