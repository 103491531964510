import React from 'react';
import { styled } from 'linaria/react';
import BlogItem from './BlogItem';
import { theme } from '../Theming/Theming';

export const itemList = {
  cols: {
    lg: 4,
    md: 3,
    sm: 2,
    xs: 2,
  },
  space: {
    lg: 2,
    md: 2,
    sm: 2,
    xs: 3,
  },
};

export const calcItemWidth = (columns, space) => {
  return (100 - space * columns) / columns + '%';
};

const ItemsWrapper = styled('div')`
  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    margin-right: -0.5rem;
    margin-left: -0.5rem;
    ${theme.above.md} {
      margin-right: -1rem;
      margin-left: -1rem;
    }
    > li {
      width: 50%;
      ${theme.above.md} {
        width: 33.333%;
      }
      ${theme.above.lg} {
        width: 25%;
      }
      .blog-item {
        margin: 0 0.5rem 0.5rem 0.5rem;
        ${theme.above.md} {
          margin: 0 1rem 2rem 1rem;
        }
      }
    }
  }
`;

const BlogItemList = ({ categories, blogPage, profileView }) => {
  if (categories && categories.length) {
    return (
      <ItemsWrapper className={blogPage && 'blog-page'}>
        <ul>
          {categories.map((category, index) => (
            <li key={category.id}>
              <BlogItem
                category={category}
                index={index}
                profileView={profileView}
              />
            </li>
          ))}
        </ul>
      </ItemsWrapper>
    );
  } else return null;
};

export default BlogItemList;
