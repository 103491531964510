import React from 'react';
import BlogItemList from './BlogItemList';
import BlogItem from './BlogItem';
import { styled } from 'linaria/react';
import { theme } from '../Theming/Theming';
import { useBlog } from './hook/useBlog';
import { config } from '../../shop.config.custom';
import { ScrollBox } from '../ui/ScrollBox';

const BlogOnPageWrapper = styled('div')`
  ${theme.above.lg} {
    .slick-prev {
      //left: -20px;
    }
    .slick-next {
      //right: -20px;
    }
  }

  //SLIDER WIDTH
  .slider-items {
    .item-card {
      margin-right: 2%;
      ${theme.only.sm} {
        width: 55%;
        margin-right: 4%;
      }
      ${theme.only.md} {
        width: 37%;
        margin-right: 3%;
      }
      ${theme.only.lg} {
        width: 27%;
      }
      ${theme.above.xl} {
        width: 21%;
      }
    }
  }
`;

const BlogOnPage = ({ limit = 20, slider }) => {
  const { articles } = useBlog({ ...config.routes.blog, limit });
  return (
    <BlogOnPageWrapper>
      {slider ? (
        <ScrollBox showNavInMobile={true}>
          {articles.map((category, index) => (
            <BlogItem
              category={category}
              key={index}
              index={index}
              className="item-card"
            />
          ))}
        </ScrollBox>
      ) : (
        <BlogItemList categories={articles} blogPage={false} />
      )}
    </BlogOnPageWrapper>
  );
};

export default BlogOnPage;
