import React from 'react';
import { styled } from 'linaria/react';
import CategoryLink from '@jetshop/ui/CategoryLink';
import Image from '@jetshop/ui/Image';
import { transparentDataImg } from '@jetshop/ui/Image/Image';

const ItemWrapper = styled('div')`
  position: relative;
  color: inherit;
  display: flex;
  flex-wrap: wrap;
  align-self: stretch;
  align-items: flex-start;
  flex-flow: column;
  text-align: center;
  .item-image {
    padding: 0;
    width: 100%;
    .item-image-container {
      //padding-top: 55%;
      //background-size: cover;
    }
    img {
      max-width: 100%;
    }
  }
  .item-text {
    padding: 15px 0 30px;
    overflow: hidden;
    width: 100%;
  }
  .item-name {
    h3 {
      font-size: 1.1rem;
    }
    a {
      text-decoration: none;
    }
  }
  .item-content {
    margin-top: 0.5rem;
    max-height: 113px;
    overflow: hidden;
    img {
      display: none !important;
    }
    p {
      font-weight: 100;
      font-size: 0.87rem;
    }
  }
`;

const BlogItem = ({
  category,
  index,
  className,
  showReadMore = false,
  profileView,
}) => {
  const getSizes = (index) => {
    let sizes = [1 / 2, 1 / 2, 1 / 2, 1 / 2];
    /*if (index === 0) {
      sizes = [1, 1, 1, 1200];
    }*/
    return sizes;
  };
  return (
    <ItemWrapper className={`blog-item ${className}`}>
      <div className="item-image">
        <CategoryLink category={category}>
          {category.images?.length ? (
            <Image
              className="item-image-container"
              src={category.images}
              alt={category.name}
              sizes={getSizes(index)}
              aspect={'4:3'}
              crop={true}
              cover
            />
          ) : (
            <Image src={transparentDataImg} aspect={'5:3'} />
          )}
        </CategoryLink>
      </div>
      <div className="item-text">
        <div className="item-name">
          <h3>
            <CategoryLink category={category}>{category.name}</CategoryLink>
          </h3>
        </div>
        {!profileView &&
          category.head &&
          category.head.metaTags &&
          category.head.metaTags.length > 0 && (
            <div className="item-content">
              <p
                dangerouslySetInnerHTML={{
                  __html: category.head.metaTags[0].content,
                }}
              />
            </div>
          )}
      </div>
    </ItemWrapper>
  );
};

export default BlogItem;
